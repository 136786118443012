import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import Swal from 'sweetalert2';

const PmModeCommentModal = ({
  open,
  careProvider,
  patient,
  time,
  startTime,
  pmMode,
  dispatch,
  endTime,
  closePmModeCommentModal
}) => {
  const [comment, setComment] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [hadPhoneConsultation, setHadPhoneConsultation] = useState(false);  
  const [canclePopup, setCanclePopup] = useState(false);
  const [noteTitle, setNoteTitle] = useState(false);
  const [errorComment, setErrorComment] = useState(true);

  /**
   * DEFAULT MESSAGE TITLE AND MESSAGE'S
   */
  const mList = [
    {
      name: "Patient Alert- Asymptomatic",
      message: "Alert received. Patient denies any symptoms. Encouraged patient to recheck measurement. Follow up measurement ____________ . Reviewed when to call MD/seek emergency care for any concerning symptoms, medical concerns, concerning symptoms accompanying _________________________ . Verbalized understanding."
    },
    {
      name: "Patient Alert- Symptomatic",
      message: "Alert received. Symptoms: _________________ . Encouraged patient to call their provider office and schedule a virtual visit or in office visit. PCP office notified."
    }, {
      name: "Patient Alert- Will continue to monitor",
      message: "Patient reiterated that even with HIGH/LOW numbers, they still feel fine. Will continue to monitor."
    }, {
      name: "RPM Monthly Phone Consultation",
      message: "Patient agreed to have their monthly phone consultation today. During our phone call the Patient shared that they had ___#____ ER visits, hospitalizations or falls in the last month. The Patient stated that they have had ___#___ Specialists visits in the last 30 days. Patient confirms that they ___(do or do not)______ have any new Health concerns at the moment. Patient also confirms that they ___(have or have not)______ experienced any Symptoms (extreme fatigue, SOB, chest pain, dizziness, swelling, HR- racing/ fluttering). Patient stated their last PCP visit was _____________ . Patient confirmed that they ___(have or have not)______ had any Medication changes in the last 30 days. We discussed their vital readings, trends and averages for the last month. We also discussed their adherence regarding the requirement of uploading at least 16 days out of each 30 days. Their Total Readings for the past 30 days was ___#____."
    },
    {
      name: "CCM Monthly Phone Consultation",
      message: "Patient answered the CCM questionnaire. ER visits, hospitalizations or falls in the last month __________________ . Any specialty visits in the last 30 days _______________ CCM Diagnosis: _____________ . SYMPTOMS: ______________ . MEDICATIONS: ______________ . Takes medications as directed _____________ . Denies barriers, changes or refills needed  _____________ . MONTHLY CONCENTRATION: ______________ . EDUCATION: ____________ . Watch for symptoms of ______________ . Reviewed when to call MD/seek emergency care for any concerning symptoms. Verbalized understanding. PATIENT’S CARE GOALS: Minimize progression of ______________ and reduce symptoms. Summary Statement: CCM monthly call performed. 20 minutes of activity has been spent reviewing the patient chart for labs, referrals, changes in condition, medication review, HEDIS review, Care plan and goals review, education to the patient regarding their health status and health care needs."
    },
    {
      name: "Chart / Adherence Review",
      message: "Chart / Adherence Review"
    },
    {
      name: "EHR Review to Follow Up on Patient Cases",
      message: "EHR Review to Follow Up on Patient Cases"
    },
    {
      name: "Follow Up for Tech Issues, Supplies etc",
      message: "Follow Up for Tech Issues, Supplies etc"
    },
    {
      name: "Left Voicemail",
      message: "Left Voicemail"
    },
    {
      name: "Other",
      message: "Other"
    },
  ];

  /**
   * INPUT CHANGE EVENT
   * @param {*} event EVENT DETAILS
   */
  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name == "subject") {
      const model = _.find(mList, model => model.name == value);
      if (model && model.message && model.message.length > 0) {
        setErrorComment(null);
        setComment(model.message)
        setNoteTitle(model.name)
      } else {
        setComment("")
        setNoteTitle("")
      }
    } else {
      setErrorComment(null);
      setComment(value)
    }
  }
  /**
   * SAVE PM SESSION NOTES
   */
  function submit() {
    console.log(noteTitle);
    if (comment.length > 0) {
      const dateNow = Date.now();
      dispatch({
        type: "DO_SAVE_TIMER",
        payload: {
          careProvider: careProvider,
          patient: patient, //receivedFrom._id,
          time: time,
          startTime: startTime,
          pmMode: pmMode,
          endTime: dateNow,
          comment: comment.trim(),
          isPublic: isPublic,
          noteTitle: noteTitle,
          hadPhoneConsultation: hadPhoneConsultation,
          isManual: false
        }
      });
      closePmModeCommentModal();
    } else {
      setErrorComment("Please enter a message!");
    }
  }

  /**
   * CANCEL SESSION POPUP
   */
  function cancelSession() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to cancel the session! If you cancel the session, the start time will be from 00:00:00.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Cancel it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setCanclePopup(true);
      }
    });
  }
  // CLOSE SESSION POPUP
  useEffect(() => {
    if (canclePopup) {
      closePmModeCommentModal();
    }
  }, [canclePopup]);

  return (
    <Modal isOpen={open} size="md" backdrop="static" data-backdrop="static">
      <ModalHeader charCode="">
        Add a note describing what happened during the session
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            className="input-date-filter remove-bottom-margin"
            type="select"
            name="subject"
            onChange={handleInputChange}
            id="exampleSelect">
            <option value="">Select Subject</option>
            {mList.map((value, index) => {
              return <option value={value.name}>{value.name}</option>;
            })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleText">Message</Label>
          <Input
            className="input-date-filter remove-bottom-margin input-message-style"
            type="textarea"
            name="body"
            readOnly={false}
            value={comment}
            onChange={handleInputChange}
            rows={10}
          />
          {errorComment && (
            <p style={{ color: "red" }}>{errorComment}</p>
          )}
        </FormGroup>
        <FormGroup check inline>
          <div style={{
            display: "flex"
          }}>
            <input
              className="input-date-filter remove-bottom-margin"
              type="checkbox"
              name="isPublic"
              checked={isPublic}
              style={{
                width: "20px",
                height: "20px"
              }}
              onChange={(e) => setIsPublic(e.target.checked)}
            />
            <Label check className="font-weight-bold" style={{ marginLeft: 5 }}>
              Public
            </Label>
          </div>
        </FormGroup>
        <FormGroup check className="mb-3" controlId="formBasicCheckbox" style={{ display: "flex" }}>
          <input
            className="input-date-filter remove-bottom-margin"
            type="checkbox"
            name="hadPhoneConsultation"
            defaultChecked={hadPhoneConsultation}
            style={{
              width: "20px",
              height: "20px"
            }}
            onChange={(e) => setHadPhoneConsultation(e.target.checked)}
          />
          <Label check className="font-weight-bold" style={{ marginLeft: 5 }}>
            PM Session included a phone consultation
          </Label>
        </FormGroup>

        <div className="div-row-center">
          <Button
            className="close-click-cls modify-cancel-btn"
            color="white"
            onClick={cancelSession} style={{padding:"15px 27px"}}>
            <i class="mdi mdi-close" style={{
              fontSize: 20
            }}></i>Cancel
          </Button>{" "}
          <Button
            color="info"
            className="save-click-cls"
            onClick={submit}
          >
            <i class="mdi mdi-content-save"></i>Save Session
          </Button>
        </div>
      </ModalBody>

    </Modal>
  );
};

export default PmModeCommentModal;
